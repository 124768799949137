import { Component, OnInit } from '@angular/core';
import { KonfiguratorService } from '../services/konfigurator.service';
import { CadFile } from '../services/models/cadfile';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/skip';

@Component({
  selector: 'app-konfigurator',
  templateUrl: './konfigurator.component.html',
  styleUrls: ['./konfigurator.component.scss']
})
export class KonfiguratorComponent implements OnInit {
  files: CadFile[];
  typ: string;
  typ_id: number = -1;
  tnut: number = -1;
  last: number = -1;
  fileTyp: string;
  kugelscheibe: Boolean;
  allTnut: Array<{id: Number, name: string}>=[];
  allLast: Array<{id: Number, name: string}>=[];
  allTypId: Array<{id: Number, name: string}>=[];
  allKugel: Array<{id: Boolean, name: string}>=[]
  resultFiles: CadFile[];

  constructor(private activatedRoute: ActivatedRoute, private konfigartionService: KonfiguratorService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.skip(1).subscribe(params => {
      this.typ = params['typ'];
      this.loadForm();
      if (this.typ == undefined) {
        console.error('Kein Typ gefunden');
      } 
    });
  }

  loadForm() {
    this.konfigartionService.getAllFiles().subscribe( (files) => {
      this.files = this.getFilesFromTyp(files);      
      this.resultFiles = [];
      this.change();
    })
  }

  reset() {
    this.kugelscheibe = undefined;
    this.tnut = -1;
    this.last = -1;
    this.typ_id = -1;
    this.fileTyp= undefined;
    this.change();
  }

  change() {

    let tmpReturn:CadFile[] = new Array();
    
    this.files.forEach( file => {
      let insert = true;

      if (this.typ != undefined && file.typ != this.typ) insert = false;
      if (this.kugelscheibe != undefined && file.kugelscheibe != this.kugelscheibe) insert = false;
      if (this.tnut != -1 && file.tnut != this.tnut) insert = false;
      if (this.last != -1 && file.last != this.last) insert = false;
      if (this.typ_id != -1 && file.typ_id != this.typ_id) insert = false;
      //if (this.fileTyp != undefined && this.getFileExtension(file.filename) != this.getFileExtension(this.tnut)) insert = false;
      if (insert) tmpReturn.push(file);
    });
    this.resultFiles = tmpReturn;
    this.changeMenus();
  }

  getFileExtension(filename) {
    return filename.split('.').pop()
  }

  changeMenus() {

    // Unique Typ für alle übrigen typen raussuchen
    this.allTypId = [];
    this.resultFiles.forEach(file => {
      let insert = true;
      this.allTypId.forEach(tmpTypId => {
        if (file.typ_id == tmpTypId.id) {
          insert = false;
        }
      })
      if (insert) this.allTypId.push({ id: file.typ_id, name: file.typ_id.toString()});
    });
    this.allTypId.push({ id: -1, name: 'Bitte wählen'});
    // sortieren noch
    this.allTypId.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

    // Unique Tnut für alle übrigen typen raussuchen
    this.allTnut = [];
    this.resultFiles.forEach(file => {
      let insert = true;
      this.allTnut.forEach(tmpTnut => {
        if (file.tnut == tmpTnut.id) {
          insert = false;
        }
      })
      if (insert) this.allTnut.push({ id: file.tnut, name: file.tnut.toString()});
    });
    this.allTnut.push({ id: -1, name: 'Bitte wählen'});
    // sortieren noch
    this.allTnut.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

    // Unique Last für alle übrigen typen raussuchen
    this.allLast = [];
    this.resultFiles.forEach(file => {
      let insert = true;
      this.allLast.forEach(tmpLast => {
        if (file.last == tmpLast.id) {
          insert = false;
        }
      })
      if (insert) this.allLast.push({ id: file.last, name: file.last.toString()});
    });
    this.allLast.push({ id: -1, name: 'Bitte wählen'});
    // sortieren noch
    this.allLast.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

    //Nachschauen ob wir auch Kugelscheiben haben
    this.allKugel = [];
    this.resultFiles.forEach(file => {
      let insert = true;
      this.allKugel.forEach(tmpkugel => {
        if (file.kugelscheibe == tmpkugel.id) {
          insert = false;
        }
      })
      if (insert) this.allKugel.push({ id: file.kugelscheibe, name: file.kugelscheibe ? 'Ja' : 'Nein'});
    });
    this.allKugel.push({ id: undefined, name: 'Bitte wählen'});
    // sortieren noch
    this.allKugel.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
  }

  getFilesFromTyp(files: CadFile[]) {
    let tmpReturnList: CadFile[] = new Array();

    files.forEach( (file, i) => {
      if (file.typ == this.typ) {
        tmpReturnList.push(file);
      }
    });
    return tmpReturnList;
  }

  getUrl(file){
    return 'https://cad.optima-spanntechnik.de/cadFiles/' + encodeURIComponent(file.filename);
  }
  
}
