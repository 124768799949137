import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CadFile } from './models/cadfile';


@Injectable({
  providedIn: 'root'
})
export class KonfiguratorService {

  apiUrl: string;
  constructor(private httpClient: HttpClient) { 
    this.apiUrl = environment.apiUrl;
  }

  getAllFiles(): Observable<CadFile[]> {
    return this.httpClient.get<CadFile[]>(this.apiUrl)
  }

}
